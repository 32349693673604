import { Button } from "@components/ui";
import routes from "@constants/routes";
import { IconHome } from "@tabler/icons-react";
import { getLocalizedStaticProps } from "@utils/translations";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";

export default function Page() {
  const { t } = useTranslation();

  return (
    <div className="container flex flex-col items-center px-6 py-10">
      <Image
        src="/illustrations/404-error.svg"
        alt="404 Error"
        className="opacity-50"
        height={300}
        width={300}
      />
      <h4 className="mt-6 mb-2 text-3xl font-bold tracking-tight text-center">
        {t("pages.error.404.title", "Page Not Found")}
      </h4>
      <p className="text-muted-foreground">{t("pages.error.404.subtitle")}</p>

      <Link href={routes.home}>
        <Button className="mt-4" variant="outline">
          <IconHome />
          {t("actions.goHome")}
        </Button>
      </Link>
    </div>
  );
}

export const getStaticProps = getLocalizedStaticProps;
